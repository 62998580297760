import React, {useCallback, useEffect, useState} from 'react';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import {
  Stack,
  TextField,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const DebouncedInput = props => {
  const theme = useTheme();
  const {
    label,
    inputRef,
    InputProps,
    muiInputProps = {},
    formControlProps = {},
  } = props;
  const [innerValue, setInnerValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const {
    error,
    fullWidth = true,
    showRequired = false,
    disableheight = false,
    ...restFormControlProps
  } = formControlProps || {};
  const {
    onChange,
    value = '',
    type = 'text',
    showCharLength = false, // eg: 8/10,
    size = 'medium', // 'small' | 'medium'
    inputProps, // eg: inputProps: {maxLength: 10},
    ...restMuiInputProps
  } = muiInputProps || {};

  // To Avoid slowness logic
  useEffect(
    () => {
      if (value) {
        setInnerValue(value);
      } else {
        setInnerValue('');
      }
    },
    [value]
  );

  const debouncedHandleOnChange = debounce(event => {
    if (onChange) {
      onChange(event);
    }
  }, 400);

  const handleOnChange = useCallback(
    event => {
      event.persist();
      const newValue = event.currentTarget.value;
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    },
    [debouncedHandleOnChange]
  );

  // Show Password logic
  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const passwordAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        edge="end"
        aria-label="toggle password visibility"
        onClick={handleTogglePasswordVisibility}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl
      fullWidth={fullWidth}
      error={error ? true : false}
      sx={
        disableheight
          ? {
              height: 'auto',
            }
          : {
              height: 55,
            }
      }
      {...restFormControlProps}>
      <TextField
        {...restMuiInputProps}
        hiddenLabel
        size={size}
        value={innerValue}
        inputRef={inputRef}
        onChange={handleOnChange}
        error={error ? true : false}
        id={`${label}-outlined-input`}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        label={
          label ? (
            <label>
              {showRequired && (
                <Typography
                  component="span"
                  sx={{color: theme.palette.error.main}}>
                  *&nbsp;
                </Typography>
              )}
              {label}
            </label>
          ) : null
        }
        slotProps={{
          input:
            type === 'password'
              ? {
                  endAdornment: passwordAdornment(),
                }
              : InputProps,
          htmlInput: inputProps,
        }}
      />
      <Stack
        direction="row"
        sx={{
          width: '100%',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}>
        <FormHelperText sx={{width: '80%', fontSize: 12, mt: 0}}>
          {error}
        </FormHelperText>
        {showCharLength && inputProps?.maxLength && (
          <Typography
            variant="body2"
            sx={{
              textAlign: 'right',
              color: 'text.secondary',
            }}>
            {innerValue?.length || 0}/{inputProps?.maxLength}
          </Typography>
        )}
      </Stack>
    </FormControl>
  );
};

DebouncedInput.propTypes = {
  label: PropTypes.string,
  InputProps: PropTypes.object,
  muiInputProps: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    inputProps: PropTypes.object,
  }),
  formControlProps: PropTypes.shape({
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    showRequired: PropTypes.bool,
    showCharLength: PropTypes.bool,
  }),
};

export default DebouncedInput;
