import {
  Box,
  List,
  Stack,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Collapse,
  Typography,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  ListItemButton,
  Drawer as MuiDrawer,
} from '@mui/material';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {styled, useTheme} from '@mui/material/styles';
import BackgroundPaper from 'mui-core/BackgroundPaper';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {themeColorMode, getCloudinaryImage} from 'mui-core/utils';
import {toggleAppDrawer, getAppTheme} from 'redux/modules/general';
import {GradientGoeducateLogo} from 'sharedResources/assets/images';

const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open, drawerWidth}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      borderRight: 'none',
    },
    ...(open && {
      ...openedMixin(theme, drawerWidth),
      '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const SideBarItem = ({sideBarOpen, data}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const {title, key, Icon, submenus, path} = data || {};
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
    if (mobile) dispatch(toggleAppDrawer(false));
  };

  return (
    <>
      <ListItem key={key} disablePadding sx={{display: 'block'}}>
        <Tooltip title={!sideBarOpen ? title : ''} placement="right">
          {path ? (
            <ListItemButton
              exact
              to={path}
              component={NavLink}
              onClick={handleClick}
              activeClassName={'Mui-selected'}
              className={isActive => (isActive ? 'Mui-selected' : '')}
              sx={[
                {
                  px: 2.5,
                  height: 40,
                  minHeight: 40,
                  position: 'relative',
                  borderRadius: '50em',
                },
                sideBarOpen
                  ? {
                      justifyContent: 'initial',
                    }
                  : {
                      justifyContent: 'center',
                    },
              ]}>
              {Icon && (
                <ListItemIcon
                  sx={[
                    {
                      zIndex: 2,
                      minWidth: 0,
                      justifyContent: 'center',
                      color: theme =>
                        themeColorMode(theme, '#000', 'text.primary'),
                    },
                    sideBarOpen
                      ? {
                          mr: 2,
                        }
                      : {
                          mr: 'auto',
                        },
                  ]}>
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText
                inset={!Icon}
                sx={[
                  {
                    zIndex: 2,
                    pl: !Icon ? '40px' : '0px',
                  },
                  sideBarOpen
                    ? {
                        opacity: 1,
                        whiteSpace: 'normal',
                      }
                    : {
                        opacity: 0,
                        whiteSpace: 'nowrap',
                      },
                ]}>
                {title}
              </ListItemText>
              {submenus && sideBarOpen && (
                <>{openSubMenu ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </ListItemButton>
          ) : (
            <ListItemButton
              component={Box}
              onClick={handleClick}
              activeClassName="Mui-selected"
              className={isActive => (isActive ? 'Mui-selected' : '')}
              sx={[
                {
                  px: 2.5,
                  height: 40,
                  minHeight: 40,
                  position: 'relative',
                  borderRadius: '50em',
                },
                sideBarOpen
                  ? {
                      justifyContent: 'initial',
                    }
                  : {
                      justifyContent: 'center',
                    },
              ]}>
              {Icon && (
                <ListItemIcon
                  sx={[
                    {
                      zIndex: 2,
                      minWidth: 0,
                      justifyContent: 'center',
                      color: theme =>
                        themeColorMode(theme, '#000', 'text.primary'),
                    },
                    sideBarOpen
                      ? {
                          mr: 2,
                        }
                      : {
                          mr: 'auto',
                        },
                  ]}>
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText
                inset={!Icon}
                sx={[
                  {
                    zIndex: 2,
                  },
                  sideBarOpen
                    ? {
                        opacity: 1,
                        whiteSpace: 'normal',
                      }
                    : {
                        opacity: 0,
                        whiteSpace: 'nowrap',
                      },
                ]}>
                {title}
              </ListItemText>
              {submenus && sideBarOpen && (
                <>{openSubMenu ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </ListItemButton>
          )}
        </Tooltip>
      </ListItem>
      {submenus && (
        <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Array.isArray(submenus) &&
              submenus.map((data, index) => (
                <SideBarItem
                  key={index}
                  data={data}
                  index={index}
                  sideBarOpen={sideBarOpen}
                />
              ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const Sidebar = ({
  data,
  userPortal,
  drawerWidth,
  DrawerHeader,
  instanceDetails,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {openMenuBar = true} = useSelector(getAppTheme) || {};
  const {logo_cloudinary, name: company_name} = instanceDetails || {};
  const SideDrawer = mobile ? MuiDrawer : Drawer;
  let userName = company_name || '';
  if (userPortal === 'super_user') {
    userName = 'Super User Portal';
  }
  const userLogo =
    getCloudinaryImage(logo_cloudinary, 'logo') || GradientGoeducateLogo;

  return (
    <>
      <SideDrawer
        anchor="left"
        open={openMenuBar}
        drawerWidth={drawerWidth}
        variant={mobile ? 'temporary' : 'permanent'}>
        <BackgroundPaper
          background={{
            light: 'white',
            dark: theme.palette.dialogDarkBG.main,
          }}
          sx={{minHeight: '100%'}}>
          <DrawerHeader />
          <Divider />
          <Stack alignItems="center" gap={2} sx={{my: 3}}>
            <Avatar
              src={userLogo}
              sx={
                openMenuBar
                  ? {
                      width: 100,
                      height: 100,
                    }
                  : {
                      width: 50,
                      height: 50,
                    }
              }
            />
            <Typography
              variant="h5"
              color={theme.palette.grey.main}
              sx={[
                {
                  transition: '0.3s all ease-in',
                },
                openMenuBar
                  ? {
                      opacity: 1,
                    }
                  : {
                      opacity: 0,
                    },
              ]}>
              {userName}
            </Typography>
          </Stack>
          <Divider variant="middle" />
          <Box py={2} pt={1} px={1}>
            <List>
              {Array.isArray(data) &&
                data.map((data, index) => (
                  <SideBarItem
                    key={index}
                    data={data}
                    index={index}
                    sideBarOpen={openMenuBar}
                  />
                ))}
            </List>
          </Box>
        </BackgroundPaper>
      </SideDrawer>
    </>
  );
};

export default Sidebar;
