import * as React from 'react';
import {PropTypes} from 'prop-types';
import {styled} from '@mui/material/styles';
import {
  Stack,
  Button,
  Dialog,
  Divider,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialogActions-root>:not(:first-of-type)': {
    marginLeft: '30px',
  },
}));

export default function CustomDialog({
  open,
  children,
  title = '',
  description,
  handleClose,
  handleSubmit,
  maxWidth = 'md',
  fullWidth = true,
  showDivider = true,
  showCloseIcon = true,
  centeredActions = false,
  showDialogActions = true,
  dialogCancelText = 'Cancel',
  dialogSubmitText = 'Submit',
  submitButtonLoading = false,
}) {
  return (
    <StyledDialog
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleClose}>
      <DialogTitle>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h5">{title}</Typography>
          {showCloseIcon && (
            <IconButton onClick={handleClose} color="primary">
              <CancelIcon color="primary" />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      {showDivider && <Divider sx={{mx: 3}} />}
      {description && <DialogContentText>{description}</DialogContentText>}
      <DialogContent>{children}</DialogContent>
      {showDialogActions && (
        <DialogActions
          sx={[
            {
              p: '24px',
            },
            centeredActions
              ? {
                  justifyContent: 'center',
                }
              : {
                  justifyContent: 'flex-end',
                },
          ]}>
          <Button variant="outlined" onClick={handleClose}>
            {dialogCancelText}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            loading={submitButtonLoading}>
            {dialogSubmitText}
          </Button>
        </DialogActions>
      )}
    </StyledDialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.element,
  handleClose: PropTypes.func,
  showDivider: PropTypes.bool,
  handleSubmit: PropTypes.func,
  description: PropTypes.string,
  showDialogActions: PropTypes.bool,
  dialogCancelText: PropTypes.string,
  dialogSubmitText: PropTypes.string,
};
