import React, {useState} from 'react';
// components
import {
  Box,
  Stack,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  TableContainer,
} from '@mui/material';
import {
  Grid,
  Button,
  CustomDialog,
  DebouncedInput,
  RequestErrorLoader,
} from 'mui-core';
import {TinyEditor} from 'core';
// utils
import {
  // AUTH_HEADER,
  JWT_HEADER,
  JSON_HEADER,
  ValidFieldsLength,
} from 'config/srm.config';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {PropTypes} from 'prop-types';
import {useSnackbar} from 'notistack';
import {useAuth} from 'mui-core/hooks';
import CloseIcon from '@mui/icons-material/Close';

function SendEmail({endpoint, users, visible, handleClose, recipientsKey}) {
  // utils
  const {token} = useAuth();
  const formik = useFormik({
    initialValues: {
      text: '',
      title: '',
      recipients: users,
    },
    validationSchema: yup.object({
      title: yup
        .string('Enter Email Subject')
        .required('Email Subject is required')
        .max(70, 'Email Subject cannot be greater than 70 characters'),
      text: yup
        .string('Enter Message Details')
        .required('Message Details is required')
        .max(2500, 'Message Details cannot be greater than 2500 characters'),
    }),
    onSubmit: values => postData(values),
  });
  const {enqueueSnackbar} = useSnackbar();
  const {description: descLen} = ValidFieldsLength;
  // states
  const [loading, setLoading] = useState(false);
  const [selectedResults, setSelectedResults] = useState(users);

  const selectedRecipientsList = key => {
    const userKeys = Object.keys(selectedResults);
    const usersList = Object.values(selectedResults);
    if (!key && usersList && usersList[0] && usersList[0].user_details) {
      //added for new schema change in user api, (uuid -> user_details.cognito_username)
      return usersList.map(
        ({user_details}) => user_details && user_details.user_uuid
      );
    } else if (key === 'uuid') {
      return usersList.map(({uuid}) => uuid);
    } else if (key === 'all') {
      return key;
    } else {
      return userKeys;
    }
  };

  const postData = async formData => {
    let requestdata = {
      ...formData,
      recipients: selectedRecipientsList(recipientsKey),
    };
    setLoading(true);
    try {
      const config = {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(requestdata),
        headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
      };
      const response = await (await fetch(endpoint, config)).json();
      if (response.Success || response.success) {
        enqueueSnackbar('Message successfully sent.', {
          variant: 'success',
        });
        handleClose();
        setLoading(false);
      } else {
        throw Error('Something went wrong with api, Try again later');
      }
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
        handleClose();
        setLoading(false);
      }
    }
  };

  const updateRecipients = obj => {
    const clonedResults = Object.assign({}, selectedResults);
    const selectedResultsSrmid = Object.keys(clonedResults);

    if (selectedResultsSrmid.includes(obj.user_account)) {
      delete clonedResults[obj.user_account];
    } else {
      clonedResults[obj.user_account] = obj;
    }
    setSelectedResults(clonedResults);
    formik?.setFieldValue('recipients', clonedResults);
  };

  const onRemoveRecipient = obj => updateRecipients(obj);

  const onEditorChange = value => formik?.setFieldValue('text', value);

  const SelectedKeys =
    recipientsKey !== 'all' && selectedResults && Object.keys(selectedResults);

  return (
    <CustomDialog
      maxWidth="md"
      open={visible}
      title="Send Email"
      dialogSubmitText="Send"
      showDialogActions={false}
      handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        {loading && <RequestErrorLoader fullScreen body={{request: true}} />}
        <Grid container spacing={3}>
          <Grid size={12}>
            <DebouncedInput
              label="Email Subject"
              formControlProps={{
                showRequired: true,
                error: formik?.errors?.title,
              }}
              muiInputProps={{
                name: 'title',
                showCharLength: true,
                inputProps: {maxLength: 70},
                value: formik?.values?.title,
                onChange: formik?.handleChange,
              }}
            />
          </Grid>
          <Grid mt={-2} size={12}>
            <InputLabel htmlFor="max-width">
              {`Recipients : ${recipientsKey === 'all' ? 'All' : ''}`}
            </InputLabel>
            {recipientsKey !== 'all' && (
              <TableContainer sx={{maxHeight: 350}}>
                <Table
                  stickyHeader
                  style={{width: '100%'}}
                  aria-label="sticky table">
                  <TableHead>
                    <TableRow style={{width: '100%'}}>
                      <TableCell style={{width: '40%'}}>Name</TableCell>
                      <TableCell style={{width: '60%'}}>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {SelectedKeys &&
                      SelectedKeys.map(item => {
                        const Name =
                          selectedResults[item].name ||
                          `${selectedResults[item].first_name ||
                            selectedResults[item]
                              .student_first_name} ${selectedResults[item]
                            .last_name ||
                            selectedResults[item].student_last_name}`;
                        const Email =
                          selectedResults[item].email ||
                          selectedResults[item].student_email;
                        return (
                          <TableRow key={selectedResults[item].user_account}>
                            <TableCell
                              style={{width: '40%', padding: '8px 16px'}}>
                              <div title={Name}>{Name}</div>
                            </TableCell>
                            <TableCell
                              style={{width: '60%', padding: '8px 16px'}}>
                              <div title={Email}>{Email}</div>
                            </TableCell>
                            {SelectedKeys.length > 1 && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                <IconButton
                                  onClick={() =>
                                    onRemoveRecipient(selectedResults[item])
                                  }>
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            )}
                          </TableRow>
                        );
                      })}
                    {selectedResults &&
                      !Object.keys(selectedResults).length && (
                        <div className="error-msg">
                          Recipients cannot be empty, please select one.
                        </div>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid size={12}>
            <FormControl label="Message Details">
              <InputLabel htmlFor="max-width">Message Details</InputLabel>
              <TinyEditor maxLen={descLen} onChange={onEditorChange} />
              <FormHelperText sx={{color: 'red'}}>
                {formik?.errors?.text}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid size={12}>
            <Stack
              spacing={4}
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button main type="submit" variant="contained">
                Send
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
}

SendEmail.propTypes = {
  users: PropTypes.array,
  visible: PropTypes.bool,
  endpoint: PropTypes.string,
  handleClose: PropTypes.func,
  recipientsKey: PropTypes.string,
};

export default SendEmail;
