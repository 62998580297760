import React, {useState, useRef, useEffect} from 'react';
import {Button} from 'mui-core';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import {formatFileSize, extractFileExtension} from 'mui-core/utils';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlined';
import {IconButton, Typography, Avatar, Box, Stack} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

export default function FileUploader({
  uploadBtnText,
  onChange,
  onDelete,
  attachments,
}) {
  const fileInputRef = useRef(null);
  const {enqueueSnackbar} = useSnackbar();
  const [uploadedFiles, setUploadedFiles] = useState(attachments || []);

  useEffect(
    () => {
      setUploadedFiles(attachments);
    },
    [attachments]
  );

  const handleFileChange = e => {
    const files = e.target.files;
    const fileList = Array.from(files);
    const fileSizeLimit = 5 * 1024 * 1024;

    const newFiles = fileList.filter(file => {
      if (file.name.endsWith('.exe')) {
        enqueueSnackbar('File(s) with .exe extension are not allowed', {
          variant: 'warning',
        });
        return false;
      } else if (file.size > fileSizeLimit) {
        enqueueSnackbar('The file size exceeds the limit of 5MB.', {
          variant: 'warning',
        });
        return false;
      }
      return true;
    });
    const updatedFiles = [...uploadedFiles, ...newFiles];
    setUploadedFiles(updatedFiles);
    onChange(updatedFiles);
  };
  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{display: 'none'}}
        onChange={handleFileChange}
      />
      {uploadedFiles &&
        uploadedFiles.map((file, index) => (
          <Box
            key={index}
            sx={theme => ({
              p: 2,
              display: 'flex',
              borderRadius: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              border: `1px solid ${theme.palette.divider}`,
            })}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <Avatar sx={{width: '48', height: '48', borderRadius: '4px'}}>
                <Typography variant="h6" sx={{color: 'text.primary'}}>
                  {extractFileExtension(file.name).toUpperCase()}
                </Typography>
              </Avatar>
              <Box direction="column">
                <Typography variant="h6" sx={{color: 'text.primary'}}>
                  {file.name}
                </Typography>
                <Typography variant="body1">
                  {formatFileSize(file.size)}
                </Typography>
              </Box>
            </Stack>
            <IconButton onClick={() => onDelete(index)}>
              <DeleteOutlineOutlined />
            </IconButton>
          </Box>
        ))}
      <Box>
        <Button variant="outlined" onClick={openFileDialog}>
          <FileUploadOutlinedIcon />
          {uploadBtnText}
        </Button>
      </Box>
    </>
  );
}

FileUploader.propTypes = {
  uploadBtnText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
