import {Divider, Box, Typography, Paper} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledContentBox = styled(Paper)(() => ({
  borderRadius: 16,
  overflow: 'hidden',
}));

const ContentBox = props => {
  const {title, children, ...restProps} = props;
  return (
    <StyledContentBox {...restProps}>
      {title && (
        <>
          <Typography variant="h5" sx={{color: 'text.primary'}}>
            {title}
          </Typography>
          <Divider sx={{my: 2}} />
        </>
      )}
      {children}
    </StyledContentBox>
  );
};

export default ContentBox;
