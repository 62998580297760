import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React from 'react';

const TimeLineFilter = ({onChange, value}) => {
  const handleChange = event => {
    if (onChange) onChange(event.target.value);
  };
  return (
    <Box sx={{my: 2}}>
      <FormControl sx={{minWidth: 200}}>
        <InputLabel id="demo-simple-select-label">Display</InputLabel>
        <Select value={value} label="Display" onChange={handleChange}>
          <MenuItem value={3}>3 Months</MenuItem>
          <MenuItem value={6}>6 Months</MenuItem>
          <MenuItem value={9}>9 Months</MenuItem>
          <MenuItem value={12}>1 Year</MenuItem>
          <MenuItem value={24}>2 Year</MenuItem>
          <MenuItem value={36}>3 Year</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default TimeLineFilter;
