import React from 'react';
import PropTypes from 'prop-types';
// components
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled, Stack, Divider, Typography} from '@mui/material';
// utils
import {themeColorMode} from 'mui-core/utils';

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const LightTooltip = styled(({className = '', ...props}) => (
  <Tooltip arrow {...props} placement="bottom" classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    padding: '1px',
    borderRadius: '8px',
    background: 'white',
    color: themeColorMode(theme, 'black', 'white'),
    boxShadow: `1px 3px 12px ${theme.palette.grey.main}`,
    backgroundColor: themeColorMode(theme, 'white', theme.palette.grey.main),
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

const CustomTooltip = ({
  title,
  children,
  tooltipData,
  showDivider = false,
  showGradient = true,
  titleVariant = 'h3',
  ...props
}) => {
  return (
    <LightTooltip
      {...props}
      title={
        <Stack
          spacing={2}
          sx={theme => ({
            padding: '32px',
            borderRadius: '8px',
            background: 'white',
            ...theme.applyStyles('dark', {
              background: 'black',
            }),
          })}>
          {title && (
            <Typography
              variant={titleVariant}
              sx={{
                fontWeight: 300,
                color: 'text.primary',
              }}>
              {title}
            </Typography>
          )}
          {showDivider &&
            (showGradient ? (
              <GradientDivider variant="middle" />
            ) : (
              <Divider variant="middle" />
            ))}
          {tooltipData}
        </Stack>
      }>
      {children}
    </LightTooltip>
  );
};

export default CustomTooltip;

CustomTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  tooltipData: PropTypes.node,
  showGradient: PropTypes.bool,
  titleVariant: PropTypes.string,
};
