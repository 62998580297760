import React from 'react';
import {TERMS, CURRENT_DATE} from 'config/srm.config';
import {Box, Stack, Button, Typography, useTheme} from '@mui/material';
import {goEducateLogo, AboutBackground} from 'sharedResources/assets/images';

const currentYear = CURRENT_DATE.getFullYear();

const AboutUs = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        px: 4,
        py: 3,
        gap: 2,
        alignItems: 'center',
        borderRadius: '20px',
        justifyContent: 'center',
        backgroundImage: `url(${AboutBackground})`,
      }}>
      <img
        width={200}
        height={40}
        src={goEducateLogo}
        alt="goEducate-white-logo"
      />
      <Box
        sx={{
          textAlign: 'center',
        }}>
        <Typography
          variant="h5"
          sx={{
            color: '#fff',
          }}>
          Version 1.0.0
        </Typography>
        <Typography variant="body2" sx={{color: '#fff'}}>
          April 2024
        </Typography>
      </Box>
      <Typography variant="body1" sx={{color: '#fff'}}>
        &copy; 2016-
        {currentYear} GoEducate, Inc. All rights reserved
      </Typography>
      <Button href={TERMS} target="_blank" variant="contained" color="primary">
        Terms of Service
      </Button>
    </Stack>
  );
};

export default AboutUs;
