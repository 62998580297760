import React from 'react';
import {
  Stack,
  useTheme,
  Backdrop,
  Typography,
  CircularProgress,
} from '@mui/material';

const Loading = ({showSpinner, title}) => (
  <Stack
    direction="row"
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    {showSpinner && <CircularProgress size={20} sx={{marginRight: 2}} />}
    <Typography variant="subtitle1" sx={{color: 'text.primary'}}>
      {title}
    </Typography>
  </Stack>
);

const Loader = ({
  showSpinner = true,
  fullScreen = false,
  title = 'Please Wait...',
}) => {
  const theme = useTheme();

  return (
    <>
      {fullScreen ? (
        <Backdrop
          open={fullScreen}
          sx={{
            color: '#fff',
            backgroundColor: '#ffffff00',
            zIndex: theme.zIndex.drawer + 1,
          }}>
          <Stack
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Loading showSpinner={showSpinner} title={title} />
          </Stack>
        </Backdrop>
      ) : (
        <Loading showSpinner={showSpinner} title={title} />
      )}
    </>
  );
};

export default Loader;
