import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ImageCropper} from 'mui-core';
import {useSnackbar} from 'notistack';
import {grey} from '@mui/material/colors';
import {styled} from '@mui/material/styles';
import {
  Box,
  Stack,
  Avatar,
  Button,
  Tooltip,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Clear,
  Close,
  CameraAltOutlined,
  FileUploadOutlined,
} from '@mui/icons-material';

const StyledAvatar = styled(Avatar)(({theme}) => ({
  width: 100,
  height: 100,
  padding: '2px',
  position: 'relative',
  backgroundColor: grey[300],
}));

const VisuallyHiddenInput = styled('input')({
  left: 0,
  width: 1,
  height: 1,
  bottom: 0,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
});

const DropZone = styled(Box)(({isDragging, theme}) => ({
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border-color 0.3s',
  ...(isDragging && {
    backgroundColor: theme.palette.grayLight.main,
  }),
}));

const ImageUploader = ({
  name,
  title,
  onDelete,
  fileType,
  logoImage,
  bannerImage,
  handleRemove,
  onImageUpload,
  logoTitle,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [cropFile, setCropFile] = useState();
  const [isDragging, setIsDragging] = useState(false);
  let imageUrl = '';
  if (bannerImage instanceof Blob || bannerImage instanceof File) {
    imageUrl = URL.createObjectURL(bannerImage);
  } else {
    imageUrl = bannerImage;
  }

  const handleUpload = event => {
    const file = event.target.files[0];
    const fileSizeLimit = 10 * 1024 * 1024;
    if (file && file.size > fileSizeLimit) {
      enqueueSnackbar('The file size exceeds the limit of 10MB.', {
        variant: 'warning',
      });
      return;
    }
    onImageUpload(file);
  };

  const handleBannerUpload = () => {
    document.getElementById('fileInput').click();
  };

  const onProfilePicSelect = e => {
    const file = e.target.files[0];
    const fileSizeLimit = 10 * 1024 * 1024;
    if (file && file.size > fileSizeLimit) {
      enqueueSnackbar('The file size exceeds the limit of 10MB.', {
        variant: 'warning',
      });
      return;
    } else if (file) {
      setCropFile(file);
      e.target.value = null;
    }
  };

  const onCropFile = data => {
    onImageUpload(data);
    setCropFile(false);
  };

  const onCancelCrop = () => {
    setCropFile(false);
  };

  const handleDragOver = event => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    onImageUpload(file);
  };

  return (
    <>
      {fileType === 'logo' && (
        <>
          <Stack
            direction="row"
            sx={{
              mb: 2,
              gap: 2,
            }}>
            <Box
              sx={theme => ({
                padding: '2px',
                position: 'relative',
                borderRadius: '100px',
                display: 'inline-flex',
                border: `1px dashed ${theme.palette.divider}`,
              })}>
              {logoImage && (
                <Tooltip title={'Remove photo'}>
                  <IconButton
                    size="small"
                    onClick={handleRemove}
                    sx={theme => ({
                      right: 6,
                      zIndex: 3,
                      width: '24px',
                      height: '24px',
                      position: 'absolute',
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: theme.palette.background.paper,
                      '&:hover': {
                        backgroundColor: 'white',
                        ...theme.applyStyles('dark', {
                          backgroundColor: 'black',
                        }),
                      },
                    })}>
                    <Close fontSize="14px" />
                  </IconButton>
                </Tooltip>
              )}
              <StyledAvatar alt="Company Logo" src={logoImage}>
                <CameraAltOutlined sx={{color: '#444'}} />
              </StyledAvatar>
            </Box>
            <Stack spacing={1}>
              <Typography variant="subtitle1" sx={{color: 'text.primary'}}>
                {logoTitle || 'Logo'}
              </Typography>
              <Typography variant="caption" sx={{color: 'text.secondary'}}>
                Min 400x400px, PNG or JPEG
              </Typography>
              <Box>
                <Button
                  name={name}
                  size="small"
                  component="label"
                  variant="outlined">
                  Select
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={onProfilePicSelect}
                  />
                </Button>
              </Box>
            </Stack>
          </Stack>
          <ImageCropper
            visible={cropFile}
            cropFile={cropFile}
            onCrop={onCropFile}
            aspectRatio={1 / 1}
            onCancel={onCancelCrop}
          />
        </>
      )}
      {fileType === 'banner' && bannerImage && (
        <Stack
          direction="row"
          sx={theme => ({
            height: 260,
            width: '100%',
            display: 'flex',
            borderRadius: 1,
            overflow: 'hidden',
            position: 'relative',
            justifyContent: 'center',
            border: `1px solid ${theme.palette.divider}`,
          })}>
          <img src={imageUrl} alt="Uploaded" style={{maxHeight: '100%'}} />
          <IconButton
            onClick={onDelete}
            sx={{
              top: 8,
              right: 8,
              width: 16,
              zIndex: 2,
              height: 16,
              position: 'absolute',
            }}>
            <Clear />
          </IconButton>
        </Stack>
      )}
      {fileType === 'banner' && !bannerImage && (
        <DropZone
          onDrop={handleDrop}
          isDragging={isDragging}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}>
          <Stack
            name={name}
            direction="column"
            htmlFor="fileInput"
            sx={theme => ({
              height: 260,
              width: '100%',
              display: 'flex',
              borderRadius: 1,
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px solid ${theme.palette.divider}`,
            })}>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              style={{display: 'none'}}
              onChange={handleUpload}
            />
            <Stack
              spacing={2}
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <IconButton
                size="large"
                color="primary"
                onClick={handleBannerUpload}>
                <FileUploadOutlined fontSize="large" />
                {title}
              </IconButton>
              <Box>
                <Typography>Add file or drop file here</Typography>
                <Typography>(Up to 10MB)</Typography>
              </Box>
            </Stack>
          </Stack>
        </DropZone>
      )}
    </>
  );
};
ImageUploader.propTypes = {
  bannerImage: PropTypes.string,
  title: PropTypes.string.isRequired,
  onImageUpload: PropTypes.func.isRequired,
};

export default ImageUploader;
