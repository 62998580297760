import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {
  Chip,
  Popper,
  useTheme,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from '@mui/material';
import ListboxComponent from './ListboxComponent';
import {grey, green, yellow, blueGrey} from '@mui/material/colors';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';

const StyledPopper = styled(Popper)({
  zIndex: 1400,
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

// => pass id="Autocomplete-with-multiple-limit-tags-with-icon-with-check-mark" to show icon in the start and check mark in selected options
// pass "icon" as property of each option as a SVG icon
// Do not pass "icon" if you don't want icon
// If two color needed in option text pass an extra property in option named "secondaryText"
// => If you want inputText to be presisted in textbox need to pass "inputValue" as well as "onInputChange" handler for multiselect

const CustomizedAutocomplete = ({
  sx,
  value,
  error,
  loading,
  onChange,
  fieldName,
  onKeyDown,
  limitTags,
  fullWidth,
  handleBlur,
  placeholder,
  openOnFocus,
  options = [],
  inputValue = '',
  onInputChange,
  size = 'medium',
  label = 'Search',
  multiple = false,
  autoComplete = true,
  showRequired = false,
  disableheight = false,
  tagColor = 'primary',
  disableClearable = false,
  includeInputInList = true,
  filterSelectedOptions = false,
  defaultValue = multiple ? [] : '',
  noOptionsText = 'No Results Found',
  disableCloseOnSelect = multiple ? true : false,
  id = 'Autocomplete-with-multiple-limit-tags',
}) => {
  const theme = useTheme();
  const [inputKeyword, setInputKeyword] = useState('');
  const bubbleColor = () => {
    switch (tagColor) {
      case 'green': {
        return {
          backgroundColor: green[400],
          color: 'background.paper',
        };
      }
      case 'yellow': {
        return {
          backgroundColor: yellow[400],
          color: grey[900],
        };
      }
      case 'blueGrey': {
        return {
          backgroundColor: blueGrey[400],
          color: 'background.paper',
        };
      }
      case 'purpleColor': {
        return {
          background: 'linear-gradient(#635BFF, #4E36F5)',
          color: 'white',
          '& .MuiChip-deleteIcon': {
            color: 'white',
          },
        };
      }
      default: {
        return {
          backgroundColor: 'primary.main',
          color: 'white',
          '& .MuiChip-deleteIcon': {
            color: 'white',
          },
        };
      }
    }
  };
  const handleInputChange = (_, v, r) => {
    if (r !== 'reset' && multiple) {
      setInputKeyword(v);
    }
    if (r === 'reset' && multiple && inputValue === '') {
      setInputKeyword('');
    }
  };

  const commonProps = {
    id,
    sx,
    size,
    value,
    options,
    loading,
    multiple,
    limitTags,
    onKeyDown,
    openOnFocus,
    defaultValue,
    autoComplete,
    noOptionsText,
    ListboxComponent,
    disableClearable,
    onBlur: handleBlur,
    disableCloseOnSelect,
    filterSelectedOptions,
    PopperComponent: StyledPopper,
    getOptionLabel: option => option.label,
    includeInputInList: includeInputInList,
    isOptionEqualToValue: (option, value) => option.key === value.key,
    renderOption: (props, option, state) => [props, option, state.index],
    ChipProps: {
      sx: bubbleColor(),
    },
    onChange: (_event, newValue, reason, details) =>
      onChange(newValue, reason, details),
    renderInput: params => (
      <TextField
        {...params}
        error={error}
        color="primary"
        name={fieldName}
        placeholder={placeholder}
        label={
          <>
            {showRequired && (
              <Typography
                color={theme.palette.error.main}
                component="span"
                pr={'2px'}>
                *
              </Typography>
            )}
            {label}
          </>
        }
      />
    ),
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      error={error ? true : false}
      sx={
        disableheight
          ? {
              height: 'auto',
            }
          : {
              height: 55,
            }
      }>
      {multiple ? (
        <Autocomplete
          {...commonProps}
          sx={{
            '& .MuiInputBase-input': {
              border: '0px !important',
            },
          }}
          slotProps={{
            paper: {
              sx: {borderTop: `1px solid ${grey[700]}`},
            },
          }}
          inputValue={inputValue || inputKeyword}
          // renderTags => Render chip to add title to the chip for tooltip
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                sx={bubbleColor()}
                label={option.label}
                title={option.label}
                {...getTagProps({index})}
              />
            ))
          }
          onInputChange={onInputChange ? onInputChange : handleInputChange}
        />
      ) : (
        <Autocomplete {...commonProps} onInputChange={onInputChange} />
      )}
      {error && (
        <FormHelperText sx={{width: '80%', fontSize: 12, mt: 0}}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomizedAutocomplete.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  onDelete: PropTypes.func,
  onKeyDown: PropTypes.func,
  openOnFocus: PropTypes.bool,
  limitTags: PropTypes.number,
  autoComplete: PropTypes.bool,
  placeholder: PropTypes.string,
  disableClearable: PropTypes.bool,
  includeInputInList: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  defaultValue: PropTypes.array || PropTypes.string,
};

export default CustomizedAutocomplete;
