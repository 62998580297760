import {ThemeSwitch} from 'mui-core';
import ProfileMenu from '../AppDrawer/ProfileMenu';
import {Menu as MenuIcon} from '@mui/icons-material';
import {styled, useTheme} from '@mui/material/styles';
import {
  Box,
  Stack,
  Toolbar,
  IconButton,
  AppBar as MuiAppBar,
} from '@mui/material';
import {useDrawer} from 'core/hooks';
import {
  goEducateLogo, // white logo
  goEducateLogoColored,
} from 'sharedResources/assets/images';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({theme, open, drawerWidth}) => ({
  zIndex: theme.zIndex.drawer + 1,
  background:
    theme.palette.mode === 'light'
      ? theme.palette.background.paper
      : theme.palette.dialogDarkBG.main,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppHeader = ({
  onSignOut,
  userDetails,
  drawerWidth,
  onLogoClick,
  profileMenuData,
}) => {
  const theme = useTheme();
  const [openMenuBar, toggleMenuBar] = useDrawer();

  return (
    <AppBar position="fixed" drawerWidth={drawerWidth}>
      <Toolbar>
        <IconButton
          edge="start"
          aria-label="drawer-control"
          onClick={() => toggleMenuBar(!openMenuBar)}>
          <MenuIcon />
        </IconButton>
        <Box
          onClick={onLogoClick}
          sx={{
            ml: 1.5,
            cursor: 'pointer',
          }}>
          <img
            width={140}
            height={40}
            alt="GoEducate-logo"
            src={
              theme.palette.mode === 'dark'
                ? goEducateLogo
                : goEducateLogoColored
            }
          />
        </Box>
        <Box component="div" sx={{flexGrow: 1}} />
        <Stack
          direction="row"
          sx={{
            gap: 2,
            alignItems: 'center',
          }}>
          <ThemeSwitch />
          <ProfileMenu
            onSignOut={onSignOut}
            userDetails={userDetails}
            profileMenuData={profileMenuData}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
