import {componentsTheme} from './../mui-core';
import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {MyriadProFonts} from 'sharedResources/assets/fonts/MyriadPro';

const getAppTheme = mode => {
  const theme = createTheme({
    palette: {
      mode,
      background: {
        main: '#F1F1F4',
        dark: '#000000',
        white: '#fff',
      },
      primary: {
        main: '#635BFF',
        dark: '#4E36F5',
      },
      secondary: {
        main: '#a956e3',
        light: '#A28DE7',
      },
      success: {
        main: '#98BF0E',
        light: '#81c784',
        dark: '#388e3c',
        contrastText: '#fff',
      },
      error: {
        main: '#b71c1c', // '#DB3024',
        light: '#d32f2f',
        dark: '#b71c1c',
        contrastText: '#fff',
      },
      grey: {
        main: '#667085',
        contrastText: '#fff',
      },
      darkGray: {
        main: '#333333',
        contrastText: '#fff',
      },
      midGray: {
        main: '#a0a4b5',
        contrastText: '#fff',
      },
      grayLight: {
        main: '#F5F5F5',
      },
      grayBorder: {
        main: '#D8D8D8',
      },
      dialogDarkBG: {
        main: '#181818',
        contrastText: '#000',
      },
      lightViolet: {
        light: '#E5E6FF',
        main: '#7b60df4d',
        contrastText: '#fff',
      },
      purpleBG: {
        main: '#f3f2fc',
      },
      taleBlue: {
        main: '#06AED4',
        contrastText: '#000',
      },
    },
    components: {
      ...componentsTheme.components,
      MuiButton: {
        styleOverrides: {
          root: ({ownerState}) => ({
            borderRadius: '8px',
            fontFamily: 'MyriadPro',
            width: ownerState.width,
            textTransform: 'capitalize',
            variants: [
              {
                props: {
                  color: 'primary',
                },
                style: {
                  color: '#635BFF',
                },
              },
              {
                props: {
                  disabled: false,
                  color: 'primary',
                  variant: 'contained',
                },
                style: {
                  color: '#fff',
                  border: '1px solid #4E36F5',
                  boxShadow: '0px 1px 2px 0px #00000014',
                  background: 'linear-gradient(#635BFF, #4E36F5)',
                },
              },
              {
                props: {
                  color: 'primary',
                  variant: 'outlined',
                },
                style: {
                  color: '#635BFF',
                  border: '1px solid #635BFF80',
                  boxShadow: '0px 1px 2px 0px #00000014',
                },
              },
              {
                props: {
                  size: 'medium',
                },
                style: {
                  fontSize: '15px',
                  minHeight: '40px',
                  padding: '4px 12px',
                  lineHeight: '24px',
                },
              },
              {
                props: {
                  size: 'small',
                },
                style: {
                  fontSize: '12px',
                },
              },
              {
                props: {
                  size: 'large',
                },
                style: {
                  fontSize: '16px',
                },
              },
            ],
          }),
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'MyriadPro';
          src: url('${MyriadProFonts.REGULAR.OTF}');
          src: url('${MyriadProFonts.REGULAR.OTF}') format('opentype');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'MyriadProLight';
          src: url('${MyriadProFonts.LIGHT.OTF}');
          src: url('${MyriadProFonts.LIGHT.OTF}') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'MyriadProSemiBold';
          src: url('${MyriadProFonts.SEMIBOLD.OTF}');
          src: url('${MyriadProFonts.SEMIBOLD.OTF}') format('opentype');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'MyriadProBold';
          src: url('${MyriadProFonts.BOLD.OTF}');
          src: url('${MyriadProFonts.BOLD.OTF}') format('opentype');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }
        /* Scrollbar styles */
        &::-webkit-scrollbar {
          width: 4px !important;
          height: 4px !important;
          background-color: rgba(123, 96, 223, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #0367FF !important;
          border-radius: 14px;
        }
    `,
      },
    },
    typography: {
      fontFamily: 'MyriadPro',
      h1: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '40px',
      },
      h2: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '27px',
      },
      h3: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '23px',
      },
      h4: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '20px',
      },
      h5: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '18px',
      },
      h6: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '16px',
      },
      // custom typography
      h7: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '14px',
      },
      body1: {
        fontSize: '14px',
      },
      body2: {
        fontSize: '12px',
      },
      bodyL: {
        fontSize: '16px',
      },
      bodyXL: {
        fontSize: '18px',
      },
      subtitle1: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '16px',
      },
      subtitle2: {
        fontSize: '15px',
      },
      caption: {
        fontSize: '13px',
      },
      metricNo: {
        fontSize: '45px',
        fontFamily: 'MyriadProLight',
      },
      pageTitle: {
        fontSize: '50px',
        fontFamily: 'MyriadProBold',
      },
      pageDesc: {
        fontSize: '17px',
      },
      popupCaptions: {
        fontSize: '30px',
        fontFamily: 'MyriadProSemiBold',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1400,
      },
    },
  });
  return responsiveFontSizes(theme);
};

export {getAppTheme};
