import {useEffect, useRef, useState} from 'react';
import {
  Editor,
  RichUtils,
  EditorState,
  ContentState,
  convertFromHTML,
  getDefaultKeyBinding,
} from 'draft-js';
import PropTypes from 'prop-types';
import FontStyles from './FontStyles';
import FontFormats from './FontFormats';
import {themeColorMode} from 'mui-core/utils';
import {stateToHTML} from 'draft-js-export-html';
import {Box, Divider, Stack, Typography, styled} from '@mui/material';
import 'draft-js/dist/Draft.css';

const EditorRoot = styled(Box)(({theme, focused, error}) => ({
  backgroundColor: theme.palette.background.paper,
  zIndex: 3,
  position: 'relative',
  border: `1px solid ${
    error
      ? theme.palette.error.main
      : focused
      ? theme.palette.primary.main
      : theme.palette.divider
  }`,
  boxShadow: focused
    ? error
      ? `0px 0px 0px 1px  ${theme.palette.error.main}`
      : `0px 0px 0px 1px ${theme.palette.primary.main}`
    : 'none',
  fontSize: 14,
  padding: '12px 16px',
  position: 'relative',
  borderRadius: 4,
  transition: '0.1s',
  [`&:hover`]: {
    borderColor: error
      ? theme.palette.error.main
      : focused
      ? theme.palette.primary.main
      : themeColorMode(theme, '#000', '#fff'),
  },
  [`&:hover .format-controls`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#f0f0f0' : '#333',
  },
}));
const EditorContainer = styled(Box)(({}) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  cursor: 'text',
  fontSize: 14,
  minHeight: 120, // here.......
  padding: '0px 8px 4px',
  [`& .public-DraftEditorPlaceholder-root`]: {
    margin: '0 -15px -15px',
  },
  [`& .public-DraftEditor-content`]: {
    padding: '4px 12px',
    margin: '0 -15px -15px',
  },
  [`.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root`]: {
    display: 'none',
  },
}));
const styleMap = {
  CODE: {
    // backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

const TextEditor = ({
  value,
  label,
  onChange,
  hint = '',
  goAIButton,
  error = '',
  placeholder,
  prefixLabel,
  name,
}) => {
  const editor = useRef();
  const [focused, setFocused] = useState(false);
  const [preValue, setPreValue] = useState(value);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const focus = () =>
    editor && editor.current ? editor.current.focus() : () => {};

  const onContentChange = editorState => {
    setEditorState(editorState);
    if (onChange) {
      var contentState = editorState.getCurrentContent();
      let html = stateToHTML(contentState);
      const _value = contentState && contentState.hasText() ? html : '';
      setPreValue(_value);
      if (value !== _value) {
        onChange(contentState && contentState.hasText() ? html : '');
      }
    }
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onContentChange(newState);
      return true;
    }
    return false;
  };

  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        onContentChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = blockType => {
    onContentChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = inlineStyle => {
    onContentChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  var contentState = editorState.getCurrentContent();
  const classNames = [];
  if (
    contentState
      .getBlockMap()
      .first()
      .getType() !== 'unstyled'
  ) {
    classNames.push('RichEditor-hidePlaceholder');
  }

  useEffect(
    () => {
      if (editorState) {
        var contentState = editorState.getCurrentContent();
        let html = stateToHTML(contentState);
        if (value !== html && value) {
          const blocksFromHTML = convertFromHTML(value);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(state));
        }
      }
    },
    [value]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditorRoot focused={focused} error={error}>
        {label && (
          <Typography
            variant="caption"
            sx={theme => ({
              top: -10,
              fontSize: 12,
              padding: '0 5px',
              position: 'absolute',
              backgroundColor: theme.palette.background.paper,
            })}>
            {label}
          </Typography>
        )}

        <Stack
          direction="row"
          className="format-controls"
          sx={{
            borderRadius: 1,
            padding: '2px 3px',
            display: 'inline-flex',
            transition: '0.1s all ease-in',
          }}>
          <FontStyles editorState={editorState} onToggle={toggleInlineStyle} />
          <Divider
            flexItem
            variant="middle"
            orientation="vertical"
            sx={{marginRight: '4px'}}
          />
          <FontFormats editorState={editorState} onToggle={toggleBlockType} />
        </Stack>

        <EditorContainer
          onClick={focus}
          className={classNames.join(' ')}
          name={name}>
          <Editor
            ref={editor}
            spellCheck={true}
            placeholder={placeholder}
            customStyleMap={styleMap}
            editorState={editorState}
            onChange={onContentChange}
            blockStyleFn={getBlockStyle}
            // onFocus={() => setFocused(true)}   // Commenting out to check slowness
            // onBlur={() => setFocused(false)}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
          />
        </EditorContainer>
        {goAIButton && (
          <Box>
            <Divider
              sx={{
                mb: 1.6,
                mt: 0.3,
                marginLeft: '-15px',
                width: `calc(100% + 30px)`,
              }}
            />
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <Typography variant="body2">{prefixLabel}</Typography>
              {goAIButton}
            </Stack>
          </Box>
        )}
      </EditorRoot>
      {hint && (
        <Typography
          variant="body2"
          sx={{
            pl: 2,
            mt: 0.5,
            color: 'text.secondary',
          }}>
          {hint}
        </Typography>
      )}
    </>
  );
};

export default TextEditor;

TextEditor.propTypes = {
  label: PropTypes.string, // text field label
  value: PropTypes.string, // html content to bind the value to text field
  error: PropTypes.string,
  onChange: PropTypes.func, // change event contains html content : (html) => {}
  placeholder: PropTypes.string, // placeholder for the text field
};
