import React from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
  useTheme,
} from '@mui/material';

const MUISelect = props => {
  const theme = useTheme();
  const {label, muiSelectProps = {}, formControlProps = {}} = props;
  const {
    error,
    fullWidth = true,
    showRequired = false,
    disableheight = false,
    ...restFormControlProps
  } = formControlProps || {};
  const {
    loading,
    options,
    onChange,
    inputProps,
    value = '',
    type = 'text',
    rounded = false,
    loadingText = 'Loading...',
    size = 'medium', // 'small' | 'medium'
    ...restMuiSelectProps
  } = muiSelectProps || {};

  return (
    <FormControl
      size={size}
      fullWidth={fullWidth}
      error={error ? true : false}
      sx={
        disableheight
          ? {
              height: 'auto',
            }
          : {
              height: 55,
            }
      }
      {...restFormControlProps}>
      <InputLabel id={`id-${label}-label`} htmlFor={label}>
        {showRequired && (
          <Typography
            component="span"
            color={theme.palette.error.main}
            pr={'2px'}>
            *
          </Typography>
        )}
        {label}
      </InputLabel>
      <Select
        {...restMuiSelectProps}
        value={value}
        label={label}
        aria-label={label}
        onChange={onChange}
        sx={
          rounded
            ? {
                '& .MuiSelect-select': {
                  padding: '8px 10px 8px 12px',
                },
                minWidth: '100%',
                borderRadius: '8px',
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
              }
            : size === 'small'
            ? {
                '& .MuiSelect-select': {
                  padding: '8px 10px 8px 12px',
                },
              }
            : {}
        }
        inputProps={{id: label, ...inputProps}}>
        {loading ? (
          <MenuItem key="loading" disabled>
            {loadingText}
          </MenuItem>
        ) : options && Array.isArray(options) && options.length > 0 ? (
          options?.map(({label, key}) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No Options Available</MenuItem>
        )}
      </Select>
      {error && (
        <FormHelperText sx={{width: '80%', fontSize: 12, mt: 0}}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MUISelect;
